import React from 'react'
import useTranslations from '../../../hooks/useTranslations'
import Button from '../buttons/Button'
import useRevealEffect from '../../../hooks/effects/useRevealEffect'
import useScrollRevealEffect from '../../../hooks/effects/useScrollRevealEffect'

const CtaPanel = () => {
  const t = useTranslations()
  const blockRef = useScrollRevealEffect()
  const titleRef = useRevealEffect({ delay: 0.4 })
  return (
    <div className="wrapper">
      <div className="cta-panel" ref={blockRef}>
        <h1
          className="cta-panel-title"
          ref={titleRef}
          dangerouslySetInnerHTML={{ __html: t('cta-panel:title') }}
        />
        <p
          className="cta-panel-subtitle"
          ref={titleRef}
          dangerouslySetInnerHTML={{ __html: t('cta-panel:subtitle') }}
        />
        <Button as="a" text="noun:free-trial-signup" href="/free-trial" />
      </div>
    </div>
  )
}

export default CtaPanel
