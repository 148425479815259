const smoothScroll = () => {
  const links = document.querySelectorAll('a[href^="#"]')

  links.forEach(anchor => {
    anchor.addEventListener('click', function (e) {
      e.preventDefault()
      document.querySelector(this.getAttribute('href')).scrollIntoView({
        behavior: 'smooth',
      })
    })
  })
}

const scrollTo = (elementId, offset) => {
  const target = document.getElementById(elementId)
  if (!target) return
  const bodyScrollTop = document.body.getBoundingClientRect().top
  const targetScrollTop = target.getBoundingClientRect().top
  const targetScroll = targetScrollTop - bodyScrollTop + offset
  window.scrollTo({ top: targetScroll, behavior: 'smooth' })
}

function formatDate(date) {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  return `${date.getDate()} ${months[date.getMonth()]}, ${date.getFullYear()}`
}

function addDay(date, n) {
  const newDate = new Date(date)
  newDate.setDate(newDate.getDate() + n)
  return newDate
}

export { smoothScroll, scrollTo, formatDate, addDay }
