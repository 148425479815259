import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import arrowImage from '../../../assets/images/arrow-right-gray-dark.svg'
import { identity } from 'lodash'

const Card = forwardRef(
  ({ children, isBig, arrow, className, onClick = identity }, ref) => {
    return (
      <div
        onClick={onClick}
        ref={ref}
        className={cx('card', {
          'is-big': isBig,
          [className]: className,
        })}
      >
        {children}
        {arrow ? (
          <div className="card-extra-icon">
            <img src={arrowImage} alt="arrow" width={69} height={53} />
          </div>
        ) : null}
      </div>
    )
  },
)

Card.propTypes = {
  children: PropTypes.node,
  isBig: PropTypes.bool,
  arrow: PropTypes.bool,
  className: PropTypes.string,
}

export default Card
