import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import useTranslations from '../../../hooks/useTranslations'
import cx from 'classnames'
import useScrollRevealEffect from '../../../hooks/effects/useScrollRevealEffect'

const AuctaTransforms = forwardRef(
  ({ title, subtitle, children, className, clear }, ref) => {
    const t = useTranslations()
    const titleRef = useScrollRevealEffect({ delay: 0.2 })

    return (
      <div
        className={cx('brick', { [className]: className, 'is-clear': clear })}
        ref={ref}
      >
        <div className="wrapper">
          <div className="brick-header">
            {title ? (
              <h2
                className="brick-title"
                dangerouslySetInnerHTML={{ __html: t(title) }}
                ref={titleRef}
              />
            ) : null}
            {subtitle ? (
              <p className="brick-subtitle" ref={titleRef}>
                {t(subtitle)}
              </p>
            ) : null}
          </div>
          {children}
        </div>
      </div>
    )
  },
)

AuctaTransforms.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.node,
}

export default AuctaTransforms
