import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import useTranslations from '../../../hooks/useTranslations'
import LinkGatsby from '../links/LinkGatsby'
import cx from 'classnames'
import arrowImage from '../../../assets/images/arrow-right-blue.svg'
import { gsap } from 'gsap'
import { identity } from 'lodash'

const CardContent = ({
  image,
  title,
  subtitle,
  extraText,
  textLink,
  hasLink,
  cardTo,
  imageWidth,
  imageHeight,
  screenReaderLink,
  animation,
  alt,
  onClick = identity,
}) => {
  const t = useTranslations()
  const cardContentRef = useRef()
  const imgRef = useRef()
  const titleRef = useRef()
  const subtitleRef = useRef()
  const linkRef = useRef()

  useEffect(() => {
    const cardContent = cardContentRef.current
    const img = imgRef.current
    const title = titleRef.current
    const subtitle = subtitleRef.current
    const link = linkRef.current

    const defaultParams = {
      duration: 0.3,
      ease: 'power3.out',
      x: 0,
      y: 0,
      rotationX: 0,
      rotationY: 0,
    }

    //Moving Animation Event
    cardContentRef.current.addEventListener('mousemove', e => {
      const { clientWidth, clientHeight } = cardContent
      const left = cardContent.getBoundingClientRect().left
      const top = cardContent.getBoundingClientRect().top
      const centerX = left + clientWidth / 2
      const centerY = top + clientHeight / 2

      let centerOffsetX = e.clientX - centerX
      let centerOffsetY = e.clientY - centerY

      const layer1Speed = 0.03
      const layer2Speed = 0.04

      gsap.to(cardContent, {
        ...defaultParams,
        z: 0,
        rotationY: centerOffsetX * 0.01,
        rotationX: centerOffsetY * -0.01,
      })

      gsap.to([img, subtitle, link], {
        ...defaultParams,
        x: centerOffsetX * layer1Speed,
        y: centerOffsetY * layer1Speed,
      })

      gsap.to(title, {
        ...defaultParams,
        x: centerOffsetX * layer2Speed,
        y: centerOffsetY * layer2Speed,
      })
    })

    //Animate Out
    cardContent.addEventListener('mouseleave', () => {
      gsap.to([cardContent, img, title, subtitle, link], defaultParams)
    })
  })

  return (
    <div
      ref={cardContentRef}
      className={cx('card-content', {
        'has-link': hasLink,
      })}
    >
      <div className="card-image">
        {animation ? (
          <lottie-player
            ref={imgRef}
            src={animation}
            background="transparent"
            speed="1"
            style={{ width: '200px', height: '172px' }}
            loop
            autoplay
          ></lottie-player>
        ) : (
          <img
            ref={imgRef}
            className="image"
            src={image}
            alt={title ? title : alt}
            width={imageWidth}
            height={imageHeight}
            loading="lazy"
          />
        )}
      </div>
      <div className="card-info">
        {title ? (
          <h2
            ref={titleRef}
            className="card-title"
            dangerouslySetInnerHTML={{ __html: t(title) }}
          />
        ) : null}

        {subtitle ? (
          <p
            ref={subtitleRef}
            className="card-subtitle"
            dangerouslySetInnerHTML={{ __html: t(subtitle) }}
          />
        ) : null}

        {extraText ? (
          <p className="card-subtitle">
            {t(extraText, {
              span: t => <span className="emphasis-text-simple">{t}</span>,
            })}
          </p>
        ) : null}
        {hasLink ? (
          <>
            <LinkGatsby
              literal
              onClick={onClick}
              to={cardTo}
              text={screenReaderLink}
              className="screen-reader-link"
            />
            {textLink ? (
              <p className="link-like" ref={linkRef}>
                <img
                  className="arrow"
                  src={arrowImage}
                  alt="arrow"
                  width={18}
                  height={18}
                />
                {t(textLink)}
              </p>
            ) : null}
          </>
        ) : null}
      </div>
    </div>
  )
}

CardContent.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  textLink: PropTypes.string,
  cardTo: PropTypes.string,
  hasLink: PropTypes.bool,
  screenReaderLink: PropTypes.string,
}

export default CardContent
