import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import useTranslations from '../../../hooks/useTranslations'
import Link from './LinkLocalized'
import arrowImage from '../../../assets/images/arrow-right-blue.svg'
import cx from 'classnames'

const LinkGatsby = forwardRef(
  ({ text, to, arrow, className, size, literal, onClick, isExternal }, ref) => {
    const t = useTranslations()
    return (
      <Link
        ref={ref}
        onClick={onClick}
        className={cx('link', {
          [className]: className,
          [size]: size,
        })}
        aria-label={literal ? text : text && t(text)}
        to={to}
      >
        {arrow ? (
          <img
            className="arrow"
            src={arrowImage}
            alt="arrow"
            width={18}
            height={18}
          />
        ) : null}
        {text ? (
          <span className="link-text">{literal ? text : t(text)}</span>
        ) : null}
      </Link>
    )
  },
)

LinkGatsby.propTypes = {
  text: PropTypes.string,
  to: PropTypes.string,
  arrow: PropTypes.bool,
  literal: PropTypes.bool,
  size: PropTypes.oneOf(['default', 'medium', 'large']),
}

LinkGatsby.defaultProps = {
  size: 'default',
}

export default LinkGatsby
