import React, { useState, forwardRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import useTranslations from '../../../hooks/useTranslations'
import { identity } from 'lodash'
import { scrollTo } from '../../utils'

const scroll = (id, scrollOffset) =>
  setTimeout(() => scrollTo(id, -scrollOffset), 100)

const Tabs = forwardRef(
  (
    {
      tabItems,
      className,
      activeTab = 0,
      onTabChange = identity,
      id,
      scrollToSelected = false,
      scrollOffset = 0,
    },
    ref,
  ) => {
    const [active, setActive] = useState(tabItems[activeTab].id)
    useEffect(() => setActive(tabItems[activeTab].id), [activeTab])
    const t = useTranslations()

    return (
      <div
        ref={ref}
        className={cx('tabs-wrapper', { [className]: className })}
        id={id}
      >
        <div className="tabs">
          {tabItems.map(item => (
            <div
              className={cx('tabs-item', { 'is-active': active === item.id })}
              key={item.id}
              id={item.id}
              onClick={() => {
                setActive(item.id)
                onTabChange(item)
                if (scrollToSelected) {
                  scroll(id, scrollOffset)
                }
              }}
            >
              {t(item.name)}
            </div>
          ))}
        </div>
        <div className="tabs-content">
          {tabItems.map(({ id, content }) => (
            <React.Fragment key={id}>
              {active === id ? content : ''}
            </React.Fragment>
          ))}
        </div>
      </div>
    )
  },
)

Tabs.propTypes = {
  tabItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      content: PropTypes.node,
    }),
  ),
}

export default Tabs
